<template>
  <div class="container">
    <v-card class="pa-4">
      <v-card-title>
        <div>
          <h2 class="mt-2 font-weight-bold headline">슈퍼멤버스를 구독할 카드를 등록해 주세요.</h2>
        </div>
      </v-card-title>
      <div class="pa-4">카드 등록 후 영업일 기준 24시간 이내 결제 및 광고 개시가 진행됩니다.</div>
      <v-card-title>
        <div>
          <h5 class="mt-8 font-weight-bold">결제 수단 추가</h5>
        </div>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-row>
          <v-col cols="12" class="text-center">
            <!--Cleave.js-->
            <card v-model="cardDetail" :invert-card.sync="invertedCard"></card>
          </v-col>
          <v-col cols="12" class="mt-12">
            <v-col cols="12" class="mb-4">
              <v-text-field
                v-model="cardDetail.name"
                name="name"
                :rules="[errors.first('name') || !errors.has('name')]"
                placeholder="소유주 성함"
                hide-details
                single-line
                outlined
                solo
                flat></v-text-field>
            </v-col>
            <v-col cols="12" class="mb-4">
              <v-text-field
                v-model="cardDetail.birth"
                name="birth"
                v-validate="'required|min:6'"
                :rules="[errors.first('birth') || !errors.has('birth')]"
                maxlength="10"
                placeholder="생년월일 6자리 또는 사업자번호 10자리"
                hide-details
                single-line
                outlined
                solo
                flat></v-text-field>
            </v-col>

            <v-row class="mb-4">
              <v-col cols="12" class="hidden-md-and-up">
                <v-text-field
                  v-model="cardDetail.number"
                  name="number"
                  :rules="[errors.first('number') || !errors.has('number')]"
                  placeholder="카드번호"
                  v-mask="'####-####-####-####'"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="hidden-sm-and-down pr-1">
                <v-text-field
                  v-model="cardDetail.numberArray[0]"
                  name="number0"
                  :rules="[errors.first('number0') || !errors.has('number0')]"
                  @input="mergeNumber"
                  placeholder="xxxx"
                  v-mask="'####'"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="hidden-sm-and-down pr-1">
                <v-text-field
                  v-model="cardDetail.numberArray[1]"
                  name="number1"
                  :rules="[errors.first('number1') || !errors.has('number1')]"
                  @input="mergeNumber"
                  placeholder="xxxx"
                  v-mask="'####'"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="hidden-sm-and-down pr-1">
                <v-text-field
                  v-model="cardDetail.numberArray[2]"
                  name="number2"
                  :rules="[errors.first('number2') || !errors.has('number2')]"
                  @input="mergeNumber"
                  placeholder="xxxx"
                  v-mask="'####'"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="hidden-sm-and-down">
                <v-text-field
                  v-model="cardDetail.numberArray[3]"
                  name="number3"
                  :rules="[errors.first('number3') || !errors.has('number3')]"
                  @input="mergeNumber"
                  placeholder="xxxx"
                  v-mask="'####'"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :class="{ 'pr-1': $vuetify.breakpoint.mdAndUp }" class="mb-4" cols="12" md="6">
                <v-text-field
                  v-model="cardDetail.expiry"
                  name="expiry"
                  :rules="[errors.first('expiry') || !errors.has('expiry')]"
                  placeholder="유효기간"
                  v-mask="'##/##'"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="mb-4">
                <v-text-field
                  v-model="cardDetail.pwd_2digit"
                  name="pwd_2digit"
                  :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                  :rules="[errors.first('pwd_2digit') || !errors.has('pwd_2digit')]"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="비밀번호 앞 두자리(법인카드 생략가능)"
                  v-mask="'##'"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat
                  @click:append="showPassword = !showPassword"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="rouge" outlined flat @click.native="resetCardData()">취소</v-btn>
        <v-btn class="rouge white-two--text" flat @click="addCard()">저장</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Card from '@/components/company/pay/card/components/Card.vue';
import { mask } from 'vue-the-mask';

const defaultCreditCardData = {
  customer_uid: '',
  card_number: '',
  customer_name: '',
  number: '',
  numberArray: [],
  name: '',
  expiry: '',
  birth: '',
  pwd_2digit: '',
};

export default {
  name: 'CardForm',
  components: {
    Card,
  },
  directives: {
    mask,
  },
  data() {
    return {
      cardDetail: defaultCreditCardData,
      invertedCard: false,
      showPassword: false,
    };
  },
  methods: {
    async mergeNumber() {
      this.cardDetail.number = this.cardDetail.numberArray.join('-');
    },
    async addCard() {
      console.log(this.cardDetail.number);
      try {
        this.cardDetail.card_number = this.cardDetail.number.replace(/[' ']/gi, '-');
        this.cardDetail.customer_name = this.cardDetail.name;
        let cardData = JSON.parse(JSON.stringify(this.cardDetail));
        let tempExpiry = cardData.expiry.split('/');
        cardData.expiry = '20' + tempExpiry[1].trim() + '-' + tempExpiry[0].trim();

        let result = await this.axios.post(
          '/company/user/card?userId=' + this.$route.params.id.replace(/\D.*$/, ''),
          cardData
        );
        if (result.data.message) {
          this.$store.commit('setMsg', result.data.message);
          this.$store.commit('setShowSnackbar', true);
        } else {
          this.$store.commit('setMsg', '카드 정보가 저장되었습니다.');
          this.$store.commit('setShowSnackbar', true);
          this.resetCardData();
          this.$router.push(`/cardform/complete`);
        }
      } catch (e) {
        this.$store.commit('setMsg', '카드 정보를 확인해 주세요.');
        this.$store.commit('setShowSnackbar', true);
        console.log(e);
        this.resetCardData();
      }
    },
    resetCardData() {
      this.cardDetail.customer_uid = '';
      this.cardDetail.card_number = '';
      this.cardDetail.customer_name = '';
      this.cardDetail.number = '';
      this.cardDetail.numberArray = [];
      this.cardDetail.name = '';
      this.cardDetail.expiry = '';
      this.cardDetail.birth = '';
      this.cardDetail.pwd_2digit = '';
    },
  },
};
</script>

<style scoped lang="scss"></style>
